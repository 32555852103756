import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Styles
import S from '../../styled';

// Model Requests
import { getSignContractTask } from '../../data/requests/task';
import { getUploadsByParentTaskId } from '../../data/requests/uploads';

// Components
import Page from '../../components/Page';
import Module from '../../components/Module';
import DownloadFileLinks from '../../components/DownloadFileLinks';

// Helpers
import { taskNameWithDueDateObject } from '../../helpers/taskNameDueDate';
import { Trans } from 'react-i18next';

// Constants
import ModuleHeader from '../../components/ModuleHeader';
import TaskInstructions from '../../components/TaskInstructions';

export default function SignContractTask(props) {
  const history = useHistory();

  const [signContractTask, setSignContractTask] = useState({});
  const [uploads, setUploads] = useState([]);
  const [isLoadingTask, setIsLoadingTask] = useState(true);
  const [isLoadingUploads, setIsLoadingUploads] = useState(true);
  const [showIncompleteTaskFields, setShowIncompleteTaskFields] = useState(true);

  const { store } = props;
  const { taskId: signContractTaskIdQueryParam } = props.match.params;

  const { token, t } = store;

  const getTask = useCallback(async () => {
    if (!token) return;

    setIsLoadingTask(true);
    try {
      const task = await getSignContractTask(token, signContractTaskIdQueryParam);

      setSignContractTask(task);
      setShowIncompleteTaskFields(!task.is_complete)
    } catch (error) {
      console.error('setSignContractTask: ', error);
    } finally {
      setIsLoadingTask(false);
    }
  }, [token, signContractTaskIdQueryParam]);

  const getUploads = useCallback(async () => {
    const parentTaskId = signContractTask.parent_task_id;
    if (!token || !parentTaskId) return;

    setIsLoadingUploads(true);
    try {
      const lookupUploads = await getUploadsByParentTaskId(token, parentTaskId);
      setUploads(lookupUploads);
    } catch (error) {
      console.error('getUploadsByTaskId', error);
    } finally {
      setIsLoadingUploads(false);
    }
  }, [token, signContractTask]);

  useEffect(() => {
    getTask();
  }, [getTask]);

  useEffect(() => {
    getUploads();
  }, [getUploads]);

  const taskCompleteNote = (
    <S.Flex row>
      <S.Text mediumWeight>
        <S.Text as="span">{t('pages.SignContractTask.note')}</S.Text>
        {t('pages.SignContractTask.taskCompleteNote')}
      </S.Text>
    </S.Flex>
  );

  const docusignContract = (
    <S.Flex
      height={"100%"}
      column
      flex={1}
      justifyStart
      alignStart
      padding={10}
      paddingLeft={53}
      >
        <S.Flex row marginBottom={10}>
          <S.Text mediumWeight>
          {showIncompleteTaskFields ? (
            <Trans t={t} i18nKey="pages.SignContractTask.viewContractIncomplete">
              A link has been sent to your email address on file to review and sign your contract. You can preview your document
              <S.Link
                lightWeight
                fontSize={16}
                target="_blank"
                rel="noreferrer"
                href={signContractTask?.envelope_url}
              >
                here.
              </S.Link>
            </Trans>
          ) : (
            <>
              <Trans t={t} i18nKey="pages.SignContractTask.viewContractComplete">
                View your signed contract in
                <S.Link
                  lightWeight
                  fontSize={16}
                  target="_blank"
                  rel="noreferrer"
                  href={signContractTask?.envelope_url}
                >
                  Docusign.
                </S.Link>
                A link was also sent to your email address on file.
              </Trans>
            </>
          )}
        </S.Text>
      </S.Flex>
      {showIncompleteTaskFields && taskCompleteNote}
    </S.Flex>
  );

  const moduleTitle = (
    <ModuleHeader moduleName={taskNameWithDueDateObject(signContractTask)} history={history} />
  );

  const isLoading = isLoadingTask || isLoadingUploads

  return (
    <Page>
      <Module
        flex={1}
        titleBlock={moduleTitle}
        isLoading={isLoading}
      >
        {signContractTask?.envelope_url ? (
          <>
            {showIncompleteTaskFields && (
              <>
                <TaskInstructions instructionsHtml={signContractTask?.instructions_html} />
                <DownloadFileLinks uploads={uploads} />
              </>
            )}
            {docusignContract}
          </>
        ) : (
          <S.Flex
          height={"100%"}
          column
          flex={1}
          justifyStart
          alignStart
          padding={10}
          paddingLeft={53}
          >
            <S.Text mediumWeight>
              Please reach out to your brand contact to provide your current email address.
            </S.Text>
          </S.Flex>
        )}
      </Module>
    </Page>
  );
}
