import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import S from '../../styled';

// Routes
import { routeNames } from '../../routing/routes';

// Intercom
import { useIntercom } from 'react-use-intercom';

// Components
import TikTokModal from '../../components/TikTokModal';
import RemoveNetworkModal from '../../components/RemoveNetworkModal';
import AddNetwork from '../AddNetworkModule';
import Module from '../../components/Module';
import NetworkCard from '../../components/NetworkCard';
import { ChevronLeft } from '@styled-icons/fa-solid';
import { ErrorCircle as ErrorIcon } from '@styled-icons/fluentui-system-regular';

// Requests
import { getCurrentUserProfilesByToken } from '../../data/requests/profile';
import { removeNetwork } from '../../data/requests/profile';
import { disconnectNetwork } from '../../data/requests/profile';
import { filterSocialProfiles } from '../../data/requests/profile';

// Authentication Provider hook
import { useAuthentication } from '../../authentication';

// Helpers
import { AUTHENTICATION_TIKTOK, NETWORKS } from '../../helpers/constants';
import useQuery from '../../helpers/useQuery';
import navigateTo from '../../helpers/navigateTo';
import { Trans } from 'react-i18next';


export default function NetworkModule(props) {
  const history = useHistory();
  const { show: showIntercom } = useIntercom();

  const { token, account, currentUser, renderModal, t } = props.store;
  const { setRenderModal, setModalContent, setCurrentProfile } = props.setStore;
  const { flex } = props;

  const [profile, setProfile] = useState({});
  const [moduleIsLoading, setModuleIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showExtendedText, setShowExtendedText] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [socialProfiles, setSocialProfiles] = useState([]);
  const { connectToNetwork } = useAuthentication();

  const { error: networkErrorMessage, network: networkInError, } = useQuery();

  const localConnectToNetwork = async (profile) => {
    if (profile.network === AUTHENTICATION_TIKTOK) {
      openTikTokModal(profile);
    } else {
      return await connectToNetwork(profile.network, false, profile);
    }
  }

  const connectTikTok = async (profile, add) => {
    const updatedProfile = await connectToNetwork(AUTHENTICATION_TIKTOK, add, profile);
    setProfile(updatedProfile);
    setSocialProfiles(filterSocialProfiles(updatedProfile));
    setRenderModal(false);
    setShowAdd(false);
  }

  const goBack = () => {
    setShowAdd(false);
  }

  const loadProfile = useCallback(async () => {
    if (!token) return;

    try {
      setModuleIsLoading(true);
      const currentProfile = await getCurrentUserProfilesByToken(token);
      setSocialProfiles(filterSocialProfiles(currentProfile));
      setCurrentProfile(currentProfile);
    } catch (error) {
      console.error('loadProfile: ', error);
    } finally {
      setModuleIsLoading(false);
    }
  }, [token, currentUser, setCurrentProfile]);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  const disconnectFromNetwork = async (profile) => {
    await disconnectNetwork(token, profile);
    await loadProfile();
  };

  const openTikTokModal = currentProfile => {
    setModalContent(<TikTokModal
      profile={currentProfile}
      show={renderModal}
      cancel={() => setRenderModal(false)}
      confirm={connectTikTok}
      store={props.store}
    />);
    setRenderModal(true);
  }

  const openRemoveModal = currentProfile => {
    setModalContent(<RemoveNetworkModal
      profile={currentProfile}
      show={renderModal}
      cancel={() => setRenderModal(false)}
      confirm={async () => {
        setRenderModal(false);
        try {
          setIsLoading(true);
          await removeNetwork(token, currentProfile);
          await loadProfile();
        } finally {
          setIsLoading(false);
        }
      }}
      store={props.store}
    />);
    setRenderModal(true);
  }

  const networkErrorContent = (
    <S.Flex flex={1} column alignCenter justifyCenter maxWidth>
      <S.SvgWrapper height={100} width={100} warning>
        <ErrorIcon />
      </S.SvgWrapper>
      <S.Flex column gap={10} marginTop={20} marginBottom={26}>
        <S.Text textAlignCenter largeSize as='h2'>
          {t('components.NetworkModule.connectProfileIssue', { network: NETWORKS[networkInError] })}
        </S.Text>
        <S.Text textAlignCenter>{networkErrorMessage}</S.Text>
      </S.Flex>
      <S.Button secondary onClick={() => navigateTo(history, routeNames.networkConnect, token, { forceRefresh: true})}>
        {t('shared.close')}
      </S.Button>
    </S.Flex>
  );

  const moreContent = (
    <S.Flex column justifyEnd alignStart whiteSpacePreWrap flex={1}>
      <S.Text smallSize padding={10} lightWeight>
        {account?.name} {t('components.NetworkModule.sideqikManagementInfo')}
      </S.Text>
      <S.Text smallSize padding={10} lightWeight>
        {t('components.NetworkModule.sideqikPrivacySecurity')}
      </S.Text>
      <S.Text smallSize padding={10} lightWeight>
        <Trans t={t} i18nKey="components.NetworkModule.sideqikStatement">
          Sideqik <S.Text smallSize as="span">does not</S.Text> ask for the ability to post on your accounts. We <S.Text smallSize as="span">cannot</S.Text> post on your behalf.
        </Trans>
      </S.Text>
      <S.Text smallSize padding={10} lightWeight>
        <Trans t={t} i18nKey="components.NetworkModule.sideqikModification">
          Sideqik will <S.Text smallSize as="span">never</S.Text> modify your accounts, follow other accounts, or like or comment on posts.
        </Trans>
      </S.Text>
      <S.Text smallSize padding={10} lightWeight>
        <Trans t={t} i18nKey="components.NetworkModule.chatIntercomMessage">
          You can <S.Link padding={0} network textDecoration="underline" href="#" onClick={() => showIntercom()}>chat with us</S.Link> if you have any questions — we’re here to help!
        </Trans>
      </S.Text>
      <S.Flex alignCenter>
        <S.Text smallSize padding={10} lightWeight>
          <S.Link target="_blank" rel="noreferrer" network href='http://help.sideqik.com/irm/why-verify-with-sideqik'>
            {t('components.NetworkModule.learnMore')}
          </S.Link>
        </S.Text>
      </S.Flex>
    </S.Flex>
  );

  const showRows = scroll => {
    return (
      <S.Flex column paddingRight={5} paddingLeft={5} flex={1}>
        <S.Flex column justifyStart alignStart flex={1} paddingBottom={64}>
          {socialProfiles && socialProfiles.map((p, i) => (
            <S.Flex key={`${p.network}-${i}`} marginTop={22} flex={0}>
              <NetworkCard
                profile={p}
                token={token}
                store={props.store}
                connectToNetwork={localConnectToNetwork}
                removeNetwork={openRemoveModal}
                disconnectNetwork={disconnectFromNetwork}
                t={t}
              />
            </S.Flex>
          ))}
        </S.Flex>
        <S.Flex row gap={14} marginTop={10} marginBottom={20} justifyCenter alignCenter>
          <S.Flex>
            <S.Button secondary height={12} width='auto' smallSize onClick={() => setShowAdd(true)}>
              + {t('components.NetworkModule.addProfile')}
            </S.Button>
          </S.Flex>
        </S.Flex>
        <S.Flex justifyCenter alignCenter flex={1}>
          {!showExtendedText && <S.Link href='#' marginBottom={20} onClick={() => setShowExtendedText(true)}>{t('components.NetworkModule.whyConnectProfiles')}</S.Link>}
          {showExtendedText && moreContent}
        </S.Flex>
      </S.Flex>
    )
  }

  if (showAdd) {
    return (
      <AddNetwork
        openTikTokModal={openTikTokModal}
        connectToNetwork={connectToNetwork}
        goBack={goBack}
        profile={profile}
        profileTypeText={t('components.AddNetworkModule.profileTypeConnect')}
        cancelText={t('shared.cancel')}
      />
    )
  }

  return (
      <Module
        flex={flex}
        isEmpty={!profile}
        hasError={networkErrorMessage}
        errorContent={networkErrorContent}
      >
        <S.Flex row alignStart justifyStart flex={0}>
          <S.Flex column alignStart justifyStart flex={0} paddingRight={10}>
            <S.Link onClick={() => navigateTo(history, routeNames.dashboard, token)} margin={0}><ChevronLeft size={20} /></S.Link>
          </S.Flex>
          <S.Flex row alignStart justifyStart>
            <S.Text paddingRight={10}>{t('components.NetworkModule.confirmProfilesToConnect')}</S.Text>
          </S.Flex>
        </S.Flex>
        <S.Visibility mobile maxWidth>
          {showRows(false)}
        </S.Visibility>
        <S.Visibility tablet desktop maxWidth>
          {showRows(true)}
        </S.Visibility>
      </Module>
  );
}
