import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import S from '../../styled';

// Components
import Page from '../../components/Page';

// Requests
import { discordAuthenticate } from '../../data/requests/user';

const clientDomain = process.env.REACT_APP_CLIENT_DOMAIN;

const DiscordCallback = () => {
  const location = useLocation();

  const getDiscordAuthToken = async () => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    const decodedState = JSON.parse(decodeURIComponent(state))
    const accountToken = decodedState.account_token
    if (code && accountToken) {
      try {
        const userData = await discordAuthenticate(accountToken, code);
        if (userData) {
          window.location.href = `${clientDomain}/${accountToken}`
        }
      } catch (err) {
        window.location.href = `${clientDomain}/${accountToken}/invalid`
        console.error('error', err)
      }
    } else {
      window.location.href = `${clientDomain}/${accountToken}/invalid`
      console.error('Error logging in with Discord')
    }
  };

  useEffect(() => {
    getDiscordAuthToken();
  }, [])

  return (
    <Page>
      <S.Flex row alignCenter justifyCenter>
        <S.Spinner />
      </S.Flex>
    </Page>
  );
}

export default DiscordCallback;
