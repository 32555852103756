export const AUTHENTICATION_FACEBOOK = 'facebook';
export const AUTHENTICATION_GOOGLE = 'google';
export const AUTHENTICATION_NONE = 'none';
export const AUTHENTICATION_TIKTOK = 'tiktok';
export const AUTHENTICATION_INSTAGRAM = 'instagram';
export const LOCAL_STORAGE_AUTHENTICATION_NETWORK_TYPE = 'SIDEQIK_AUTHENTICATION_NETWORK';

export const AUTHED_STATUS_UNAUTHENTICATED = 'unauthenticated';
export const AUTHED_STATUS_UNAUTHORIZED = 'unauthorized';
export const AUTHED_STATUS_AUTHENTICATED = 'authenticated';
export const AUTHED_STATUS_INVALID = 'invalid';
export const AUTHED_STATUS_LOADING = 'loading';

export const FLASH_TYPE_SUCCESS = 'success';
export const FLASH_TYPE_ERROR = 'error';

export const VIDEO_DATA_TYPE = 'video/';
export const FILE_DATA_TYPE = 'application/';

export const CONTENT_REVIEW_STATUS_APPROVED = 'approved';
export const CONTENT_REVIEW_STATUS_APPROVE_CONTENT = 'approve_content';
export const CONTENT_REVIEW_STATUS_FEEDBACK_GIVEN = 'feedback_given';
export const CONTENT_REVIEW_STATUS_AWAITING_CONTENT = 'awaiting_content';

export const POST_CONTENT_TASK = 'PostContentTask';
export const FILE = 'file';
export const CONTENT = 'content';

export const PORTAL_AFFILIATE_DISABLED = 'portal_affiliate_disabled';
export const PORTAL_PAYMENTS_DISABLED  = 'portal_payments_disabled';
export const PORTAL_CAMPAIGN_SUMMARY 	 = 'portal_campaign_summary';

export const DISCORD_CONFIG = {
  clientId: process.env.REACT_APP_DISCORD_CLIENT_ID,
  redirectUri: process.env.REACT_APP_DISCORD_REDIECT_URI
}

export const NETWORKS = {
  blog: 'Blog',
  facebook: 'Facebook',
  google: 'Google',
  instagram: 'Instagram',
  pinterest: 'Pinterest',
  soundcloud: 'SoundCloud',
  steam: 'Steam',
  tumblr: 'Tumblr',
  twitch: 'Twitch',
  twitter: 'Twitter',
  vimeo: 'Vimeo',
  website: 'Website',
  youtube: 'YouTube',
  mixer: 'Mixer',
  snapchat: 'Snapchat',
  linkedin: 'LinkedIn',
  flickr: 'Flickr',
  patreon: 'Patreon',
  medium: 'Medium',
  spotify: 'Spotify',
  foursquare: 'Foursquare',
  tiktok: 'TikTok'
}
// eslint-disable-next-line
export const EMAIL_VALIDATION_REGEX = /(\w\.?)+@[\w\.-]+\.\w{2,}/;

export const ERROR_403 = {
  status: '403',
  error: "Unable to authentication user for portal",
  error_code: "Forbidden",
};

export const authErrors = () => {
  return {
    default: {
      name: 'default',
      message: 'We could not log you in at this time.'
    },
    PortalLockedError: {
      name: 'PortalLocked',
      message: 'Your account has been locked.'
    },
    MultipleAccountContactsError: {
      name: 'MultipleAccountContactsError',
      message: 'Unable to find unique influencer for account.'
    },
    NoAccountContactReport: {
      name: 'NoAccountContactReportError',
      message: 'We ran into an issue setting up your account. Our team has been notified and is working to resolve the issue.'
    },
    NoActiveAccountError: {
      name: 'NoActiveAccountError',
      message: 'The email you provided is not associated with this brand account.'
    },
    EmailDoesntMatchContactsError: {
      name: 'EmailDoesntMatchContactsError',
      message: 'We could not find an account with the provided email address.'
    },
    ContactBelongsToAnotherUserError: {
      name: 'ContactBelongsToAnotherUserError',
      message: 'We could not find an account with the provided email address.'
    },
    NoEmailForCreatorError: {
      name: 'NoEmailForCreatorError',
      message: 'Unable to find influencer email associated with brand account.'
    }
  }
};

export const DELEGATE_RELATIONSHIP_LOCAL_STORAGE_NAME = 'current_delegate_relationship';

// These values come from the backend as snake_case but we convert to camelCase to use them as i18n keys.
export const FORM_FIELD_TYPES = {
  BIRTHDAY: 'birthday',
  CITY: 'city',
  COUNTRY: 'country',
  CUSTOM_CHECKBOX: 'customCheckbox',
  CUSTOM_CHECKBOX_GROUP: 'customCheckboxes',
  CUSTOM_DROPDOWN: 'customDropdown',
  CUSTOM_FILE: 'customFile',
  CUSTOM_TEXT_AREA: 'customParagraph',
  CUSTOM_RADIO: 'customRadio',
  CUSTOM_SINGLE_LINE: 'customText',
  CUSTOM_WEBSITE: 'customWebsite',
  DESCRIPTIVE_TEXT: 'descriptiveText',
  EMAIL: 'email',
  GENDER: 'gender',
  LABELS_DROPDOWN: 'labels',
  LOCATION: 'location',
  NAME: 'name',
  NEWSLETTER_SUBSCRIBE: 'subscribe',
  ORGANIZATION: 'organization',
  PASSWORD: 'password',
  PHONE: 'phone',
  POSTAL_CODE: 'postCode',
  RULES_AGREEMENT: 'officialRules',
  SECTION_BREAK_LABEL: 'label',
  STATE: 'state',
  STREET: 'street',
  TAX_ID: 'taxId',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  ZIP_CODE: 'zipCode'
};

export const FORM_FIELD_DEFAULT_LABELS = {
  [FORM_FIELD_TYPES.BIRTHDAY]: 'Birthday',
  [FORM_FIELD_TYPES.CITY]: 'City',
  [FORM_FIELD_TYPES.COUNTRY]: 'Country',
  [FORM_FIELD_TYPES.GENDER]: 'Gender',
  [FORM_FIELD_TYPES.LOCATION]: 'Location',
  [FORM_FIELD_TYPES.NEWSLETTER_SUBSCRIBE]: 'Sign me up for the newsletter',
  [FORM_FIELD_TYPES.ORGANIZATION]: 'Organization',
  [FORM_FIELD_TYPES.PASSWORD]: 'Choose Password',
  [FORM_FIELD_TYPES.PHONE]: 'Mobile Number',
  [FORM_FIELD_TYPES.POSTAL_CODE]: 'Postal Code',
  [FORM_FIELD_TYPES.RULES_AGREEMENT]: 'I agree to the official rules',
  [FORM_FIELD_TYPES.STATE]: 'State',
  [FORM_FIELD_TYPES.STREET]: 'Street Address',
  [FORM_FIELD_TYPES.TAX_ID]: 'Tax ID',
  [FORM_FIELD_TYPES.TERMS_AND_CONDITIONS]: 'Terms and Conditions',
  [FORM_FIELD_TYPES.ZIP_CODE]: 'Zip Code',
};

export const DEFAULT_TERMS_LABEL = 'Accept Terms';
