import React from 'react';
import S from '../../../styled';

// Icons
import {
  Square as SquareIcon,
  CheckSquare as CheckIcon,
} from '@styled-icons/fa-regular';

import {
  PhotoVideo as PhotoVideoIcon,
  Link as LinkIcon,
  File as FileIcon,
  FileSignature as FileSignatureIcon,
} from '@styled-icons/fa-solid';

import {
  ReactComponent as CampaignBriefTask
} from '../../../assets/svg/campaign_brief.svg';

export default function TaskIcon({ type, complete }) {
  let todoIcon = (
    complete ? <CheckIcon padding={3} /> : <SquareIcon padding={3} />
  );
  let icon = null;
  switch (type) {
    case 'PostContentTask':
      icon = <PhotoVideoIcon padding={3} />
      break;
    case 'CampaignBriefTask':
      icon = <CampaignBriefTask padding={3} />
      break;
    case 'ConnectProfileTask':
      icon = <LinkIcon padding={3} />
      break;
    case 'FileRequestTask':
      icon = <FileIcon padding={3} />
      break;
    case 'SignContractTask':
      icon = <FileSignatureIcon padding={3} />
      break;
    default:
      icon = todoIcon;
  }

  return (
    <S.SvgWrapper height={40} width={40} marginRight={10} padding={8} border sharpBorderRadius displayInline>
      {icon}
    </S.SvgWrapper>
  );
}
