import { useEffect, useState } from "react";

export function useFeatureFlag(store, feature) {
  const [val, setVal] = useState(false);
  const { account, accountIsLoading } = store;
  useEffect(() => {
    if (accountIsLoading || !account || !account.features) return;
    setVal(account?.features?.indexOf(feature) >= 0);
  }, [accountIsLoading, account, setVal, feature]);
  return val;
}
