import React, { useState, useEffect, useCallback } from 'react';
import S from '../../styled';
import { getTheme } from '../../styled/Themes';
import { useHistory } from 'react-router-dom';

// Routes
import { routeNames } from '../../routing/routes';

// Model Requests
import { getTasksByToken } from '../../data/requests/task';

// Components
import Module from '../Module';
import TaskGroup from './TaskGroup';

// Helpers
import navigateTo from '../../helpers/navigateTo';

// Icons
import {
  CheckCircle as CheckIcon
} from '@styled-icons/fa-regular';

export default function TasksModule(props) {
  const { store: { token, t }, setStore } = props;
  const theme = getTheme(props.store.theme);

  const [isMounted, setIsMounted] = useState(true);
  const [incompleteData, setIncompleteData] = useState([]);
  const [moduleIsLoading, setModuleIsLoading] = useState(true);

  const history = useHistory();

  const loadModuleData = useCallback(async () => {
    if (!token) return;

    try {
      setModuleIsLoading(true);
      let incompleteCampaignIds = [];

      const incompleteDataResponse = await getTasksByToken(token);

      if (incompleteDataResponse && incompleteDataResponse.length) {
        incompleteCampaignIds = incompleteDataResponse.map(({ id }) => id);
      }

      setIncompleteData(incompleteDataResponse || []);
    } catch (error) {
      console.error('loadModuleData: ', error);
    } finally {
      if (isMounted) setModuleIsLoading(false);
    }
  }, [token, isMounted]);

  const navigateToAllTasks = (e) => {
    e.preventDefault();
    navigateTo(history, routeNames.tasks, token);
  }

  useEffect(() => {
    loadModuleData();
    return () => setIsMounted(false);
  }, [loadModuleData]);

  const moduleTitle = (
    <S.Text clickable hoverable onClick={navigateToAllTasks}>
     {t('components.TasksModule.title')}
    </S.Text>
  );

  const isEmpty = !incompleteData.length

  const emptyContent = (
    <S.Flex column>
      <S.Flex row paddingBottom={10}>
        <S.SvgWrapper height={50} width={50}>
          <CheckIcon style={{ color: theme.button.color.default }} />
        </S.SvgWrapper>
      </S.Flex>

      <S.Flex row textAlignCenter padding={5}>
        <S.Text boldWeight smallSize>
          {t('components.TasksModule.noPendingTasks')}
        </S.Text>
      </S.Flex>

      <S.Flex row textAlignCenter padding={5}>
        <S.Text smallSize lightWeight>
          {t('components.TasksModule.tasksWillAppearHere')}
        </S.Text>
      </S.Flex>
    </S.Flex>
  );

  const topRightContent = (
    <S.Flex row alignEnd justifyEnd flex={1}>
      <S.Link
        href={'#'}
        whiteSpaceNoWrap
        muted
        hoverable
        clickable
        flex={0}
        onClick={navigateToAllTasks}
      >
        {t('components.TasksModule.viewAllTasks')}
      </S.Link>
    </S.Flex>
  );

  return (
    <Module
      titleBlock={moduleTitle}
      flex={props.flex}
      isLoading={moduleIsLoading}
      isEmpty={isEmpty}
      topRightContent={topRightContent}
      emptyContent={emptyContent}
    >
      <S.Flex column justifyStart style={{ rowGap: '10px' }}>
        {incompleteData.map((taskGroup, i) => (
          <TaskGroup
            key={`${taskGroup.id}-${i}`}
            token={token}
            profile={props.store.currentProfile}
            taskGroup={taskGroup}
            setStore={setStore}
            onTaskUpdated={loadModuleData}
            store={props.store}
          />
        ))}
      </S.Flex>
    </Module>
  );
}
