import api from '../api';

const baseUrl = (token) => `tasks/${token}`;

export const getTasksByToken = (token, completed = false) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/grouped`,
    params: { completed },
  });

export const getTasksByCampaignAndToken = (token, campaignId, completed = false) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${campaignId}`,
    params: { completed },
  });

export const getPostContentTask = (token, taskId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${taskId}/post_content_task`,
  });

export const getCampaignBriefTask = (token, taskId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${taskId}/campaign_brief_task`,
  });

export const getTodoTask = (token, taskId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${taskId}/todo_task`,
  });

export const getSignContractTask = (token, taskId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${taskId}/sign_contract_task`,
  });

export const getFileRequestTask = (token, taskId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${taskId}/file_request_task`,
  });

export const getShareCodesTask = (token, taskId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${taskId}/share_code_task`,
  });

export const getContentReviews = (token, taskId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${taskId}/content_reviews`,
  });

export const getContentReview = (token, taskId, reviewId) =>
  api({
    method: 'get',
    url: `${baseUrl(token)}/${taskId}/content_reviews/${reviewId}`,
  });

export const updateContentReview = (token, taskId, contentReviewId, data) =>
  api({
    method: 'put',
    url: `${baseUrl(token)}/${taskId}/content_reviews/${contentReviewId}`,
    data
  });

export const updateTasks = (token, taskId) =>
	api({
		method: 'patch',
		url: `${baseUrl(token)}/${taskId}/update_task`
	});
